import { Suspense, lazy } from 'react';

import { Routes, Route } from 'react-router-dom';

import ErrorBoundary from './errors/ErrorBoundary';
import ProtectedRoute from '../routes/ProtectedRoute';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import AccountPlans from './about/accountPlans/AccountPlans';
import { checkEnv } from '../utils/Utils';

const MenuPagesList = lazy(() =>
    import('./content/contentLogic/MenuPagesList'),
);
const MenuPageDetail = lazy(() =>
    import('./content/contentLogic/MenuPageDetail'),
);
const SettingPage = lazy(() => import('../pages/settings/SettingPage'));
const AboutPage = lazy(() => import('../pages/about/AboutPage'));
const HelpPage = lazy(() => import('../pages/help/HelpPage'));
const CompanyPage = lazy(() => import('../pages/settings/CompanyPage'));
const HomePageList = lazy(() => import('./content/contentLogic/HomePageList'));
const PlanPage = lazy(() => import('../pages/payments/PlanPage'));
const SuccessPage = lazy(() => import('../pages/payments/SuccessPage'));
const AccountModal = lazy(() => import('./reusable/modal/AccountModal'));
const PaymentSuccessPage = lazy(() =>
    import('./profilePage/payment/PaymentSuccessPage'),
);

const Content = ({ siteSetting, isMobile }) => {
    const isProduction = checkEnv();

    return (
        <Suspense>
            <section className="content top-85 section">
                <Routes>
                    <Route
                        path="/"
                        element={
                            <ErrorBoundary>
                                <ProtectedRoute
                                    element={
                                        <HomePageList
                                            isMobile={isMobile}
                                            siteSetting={siteSetting}
                                        />
                                    }
                                />
                            </ErrorBoundary>
                        }
                    />
                    <Route
                        path="/:menu"
                        element={
                            <ErrorBoundary>
                                <ProtectedRoute element={<MenuPagesList />} />
                            </ErrorBoundary>
                        }
                    />
                    <Route
                        path="/:menu/:slug"
                        element={
                            <ErrorBoundary>
                                <ProtectedRoute element={<MenuPageDetail />} />
                            </ErrorBoundary>
                        }
                    />
                    <Route
                        path="/settings"
                        element={
                            <ErrorBoundary>
                                <ProtectedRoute element={<SettingPage />} />
                            </ErrorBoundary>
                        }
                    />
                    <Route
                        path="settings/organization"
                        element={
                            <ErrorBoundary>
                                <CompanyPage />
                            </ErrorBoundary>
                        }
                    />

                    <Route
                        path="/settings/billing/plans"
                        element={
                            <ErrorBoundary>
                                {isProduction ? <PlanPage /> : <AccountPlans />}
                            </ErrorBoundary>
                        }
                    />
                    <Route
                        path="/settings/billing/success"
                        element={
                            <ErrorBoundary>
                                <SuccessPage />
                            </ErrorBoundary>
                        }
                    />
                    <Route
                        path="/payment/success"
                        element={
                            <ErrorBoundary>
                                <PaymentSuccessPage />
                            </ErrorBoundary>
                        }
                    />
                    <Route
                        path="/about"
                        element={
                            <ErrorBoundary>
                                <AboutPage />
                            </ErrorBoundary>
                        }
                    />
                    <Route
                        path="/help"
                        element={
                            <ErrorBoundary>
                                <HelpPage />
                            </ErrorBoundary>
                        }
                    />
                    <Route
                        path="/policy"
                        element={
                            <ErrorBoundary>
                                <PrivacyPolicy />
                            </ErrorBoundary>
                        }
                    />
                </Routes>

                <Suspense fallback={<div>Loading...</div>}>
                    <AccountModal />
                </Suspense>
            </section>
        </Suspense>
    );
};

export default Content;
