import { useCallback, useContext, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import {
    Box,
    Button,
    Chip,
    Container,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

import { CheckCircleRounded, ContentPasteRounded } from '@mui/icons-material';

import useAuthCheck from '../../../hooks/useAuthModal';
import { AlertMessageContext } from '../../../context/AlertMessageContext';
import { LoaderContext } from '../../../context/LoaderContext';
import { config } from '../../../config/config';
import { checkEnv } from '../../../utils/Utils';
import { userAxios } from '../../../utils/api';
import { getAuthData } from '../../../utils/auth';
import { logger } from '../../../utils/SiteHelpers';
import { PlanIds } from '../../../utils/constants';

const PlanData = (props) => {
    const { subscription, isTrialExpired, isNested, isCurrentSubscription } =
        props;
    const { user, isAuthenticated, loginWithRedirect } = useAuth0();
    const [coupon, setCoupon] = useState('');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('card');
    const apiUrl = `${config.subscriptionUrl}create-session`;
    const { setErrorMessage } = useContext(AlertMessageContext);
    const { setShowLoader, setLoaderMessage } = useContext(LoaderContext);
    const isProduction = checkEnv();
    const checkAuthentication = useAuthCheck();

    const { id, price, description, content } = subscription;
    const subscriptionPrice = price === 0 ? 'FREE' : `$${price}`;
    const couponField = id === PlanIds.ANNUAL;
    const isSubscriptionDisabled = isCurrentSubscription || isTrialExpired;
    const paymentMethod =
        selectedPaymentMethod === 'card' ? 'stripe' : selectedPaymentMethod;

    const handleCouponChange = (e) => setCoupon(e.target.value);

    const handlePaste = async () => {
        try {
            const clipboardText = await navigator.clipboard.readText();
            setCoupon(clipboardText);
        } catch (error) {
            logger.error('Failed to read clipboard content: ', error);
        }
    };

    const HtmlToFormattedText = ({ htmlContent }) => {
        // Ensure the HTML content is not double-escaped
        const sanitizedHtmlContent = htmlContent.replace(/\\\"/g, '"');

        // Create the icon as a string
        const checkIconHtml = `<span style="color: green; margin-right: 8px; font-weight: bold;">&#10003;</span>`;

        // Modify <ul> tag to remove padding-left
        let modifiedHtmlContent = sanitizedHtmlContent.replace(
            /<ul>/g,
            '<ul style="padding-left: 1rem;">',
        );

        const updatedHtmlContent = modifiedHtmlContent.replace(
            /<li>/g,
            `<li style="display: flex; align-items: center;">${checkIconHtml}`,
        );

        return (
            <div
                className="quill-content"
                dangerouslySetInnerHTML={{ __html: updatedHtmlContent }}
            />
        );
    };

    const processToCheckout = useCallback(
        async (trial, subscription_id) => {
            if (!isAuthenticated) {
                if (isNested) {
                    checkAuthentication();
                } else {
                    loginWithRedirect();
                }
                return;
            }

            try {
                setShowLoader(true);
                setLoaderMessage('Processing');

                const { email, sub: auth0id } = user;
                const authData = await getAuthData(email, auth0id);
                const { accessToken, userId } = authData;

                if (!accessToken) {
                    throw new Error('No access token available.');
                }

                const payload = {
                    user_id: userId,
                    coupon,
                    trial,
                    subscription_id,
                    payment_method: paymentMethod,
                };

                const { data } = await userAxios.post(apiUrl, payload, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (data.error) {
                    setErrorMessage(data.message);
                } else {
                    window.location = data.redirectUrl;
                }
            } catch (error) {
                logger.error(error.response);
                setErrorMessage('An unknown error occurred.');
            } finally {
                setShowLoader(false);
            }
        },
        [user, coupon, apiUrl, paymentMethod],
    );

    const handleSubscribeClick = () => {
        const trial = id === PlanIds.TRIAL ? 1 : 0;
        processToCheckout(trial, id);
    };

    return content ? (
        <Box sx={{ p: 2 }}>{content}</Box>
    ) : (
        <Container maxWidth="xs" sx={{ py: 2 }}>
            {isCurrentSubscription && (
                <Box sx={{ textAlign: 'center', mb: 2 }}>
                    <Chip
                        icon={<CheckCircleRounded />}
                        label="Your Current Plan"
                        color="primary"
                        variant="outlined"
                        sx={{ fontSize: '14px', fontWeight: 'bold' }}
                    />
                </Box>
            )}
            {subscriptionPrice && (
                <Box sx={{ textAlign: 'center' }}>
                    <Button
                        onClick={handleSubscribeClick}
                        disabled={isSubscriptionDisabled}
                    >
                        {subscriptionPrice}
                    </Button>
                </Box>
            )}

            {description && (
                <Box sx={{ textAlign: 'center', my: 1 }}>
                    <HtmlToFormattedText htmlContent={description} />
                </Box>
            )}

            <Grid container spacing={2} justifyContent="center">
                {couponField && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            type="text"
                            value={coupon}
                            onChange={handleCouponChange}
                            placeholder="Coupon"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            onClick={handlePaste}
                                        >
                                            <ContentPasteRounded />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                )}

                {!isProduction && id !== PlanIds.TRIAL && (
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="payment-method-label">
                                Payment Method
                            </InputLabel>
                            <Select
                                labelId="payment-method-label"
                                value={selectedPaymentMethod}
                                onChange={(e) =>
                                    setSelectedPaymentMethod(e.target.value)
                                }
                                label="Payment Method"
                            >
                                <MenuItem value="card">
                                    Credit Card / Debit Card
                                </MenuItem>
                                <MenuItem value="paypal">PayPal</MenuItem>
                                <MenuItem value="stripe">Stripe</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}

                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Button
                        onClick={handleSubscribeClick}
                        disabled={isSubscriptionDisabled}
                        fullWidth
                    >
                        {isCurrentSubscription
                            ? 'Subscribed'
                            : isTrialExpired
                            ? 'Subscribe'
                            : 'Subscribe'}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default PlanData;
