import React from 'react';
import useHandleAuthenticatedLink from '../useHandleAuthenticatedLink';
import SuitesToolbar from '../../../suites/SuitesToolbar';
import { Box, Button, Container, Typography } from '@mui/material';
import FlyerAndVideo from './FlyerAndVideo';
import { ExternalLink } from '../../../../utils/SiteHelpers';

export const CustomButton = ({ onClick, children }) => (
    <Button onClick={onClick} sx={{ mx: 1 }}>
        {children}
    </Button>
);

const AccessPage = () => {
    const handleClick = useHandleAuthenticatedLink();

    return (
        <Container>
            <Typography>
                You must{' '}
                <ExternalLink
                    text="Sign Up"
                    href={'https://www.pathlms.com/toolkitlaw/sign_up'}
                />{' '}
                on our companion Path LMS (learning management system) before
                you can{' '}
                <ExternalLink
                    text="Sign In"
                    href={'https://www.pathlms.com/toolkitlaw/sign_in'}
                />{' '}
                to complete our continuing legal education (CLE) webinar
                content. Before accessing our continuing legal education (CLE)
                online webinars, confirm that our current webinar has been
                approved for your{' '}
                <ExternalLink
                    href={'/webinars/jurisdictions'}
                    text="Jurisdiction"
                    newTab={false}
                />{' '}
                in the 80 countries with English-centric legal systems. If your
                Jurisdiction has not yet approved our webinars, please{' '}
                <ExternalLink
                    href="/webinars/share-cle"
                    text="share"
                    newTab={false}
                />{' '}
                our webinar to your jurisdiction. See our webinars video and
                flyer below.
            </Typography>
            <Box display="flex" justifyContent="center" my={2}>
                <CustomButton
                    onClick={() =>
                        handleClick(
                            'https://www.pathlms.com/toolkitlaw/sign_in',
                        )
                    }
                >
                    CLE Login
                </CustomButton>

                <CustomButton
                    onClick={() =>
                        handleClick(
                            'https://www.pathlms.com/toolkitlaw/sign_up',
                        )
                    }
                >
                    CLE Sign Up
                </CustomButton>
            </Box>
            <FlyerAndVideo />
            <SuitesToolbar />
        </Container>
    );
};

export default AccessPage;
