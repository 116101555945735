import { Box, Link, Typography } from '@mui/material';

import {
    ArrowBackIosRounded,
    ArrowForwardIosRounded,
    AttachMoney,
    CloseRounded,
    DownloadRounded,
    LaunchRounded,
    SaveRounded,
} from '@mui/icons-material';

import countriesData from '../../components/vendors/countries.json';
import { commonButtons } from '../../config/Buttons';
import { convertToKey } from '../../utils/Helpers';
import { checkEnv } from '../../utils/Utils';
import { generateSlug, toggleAccordion } from '../../utils/SiteHelpers';
import { config } from '../../config/config';

export const stripeUrl = 'https://stripe.com/';
export const webstoreTitle = 'My User Webstore';
export const webstoreAccordionName = generateSlug(webstoreTitle);
export const servicesTitle = 'My Services';
export const salesTitle = 'My Sales';
const isProduction = checkEnv();
const { siteUrl, imageUrl, vendorUrl } = config;

export const PriceDescription = ({ currentId, targetId }) => {
    const handleClick = () => {
        toggleAccordion(generateSlug(currentId), generateSlug(targetId));
    };

    return (
        <Typography gutterBottom>
            When you add a new service, you enter the price you want to receive.
            When we publish it to your{' '}
            <Link
                component="button"
                onClick={handleClick}
                sx={{ cursor: 'pointer' }}
            >
                User Webstore
            </Link>
            , it will display the prices with our processing fees added that the
            Buyer will pay.
        </Typography>
    );
};

// Functions related to header background modal
const handleCloseModal = (toggleModal) => {
    toggleModal(false);
};

const handlePreviousImage = (
    options,
    imageIndex,
    setImageIndex,
    setSelectedImage,
) => {
    if (imageIndex > 0) {
        const newIndex = imageIndex - 1;
        console.log('Previous Image Index:', newIndex);
        setImageIndex(newIndex);
        setSelectedImage(options?.[newIndex]);
        console.log('Updated selectedImage:', options?.[newIndex]);
    }
};

const handleNextImage = (
    options,
    imageIndex,
    setImageIndex,
    setSelectedImage,
) => {
    if (imageIndex < options.length - 1) {
        const newIndex = imageIndex + 1;
        console.log('Next Image Index:', newIndex);
        setImageIndex(newIndex);
        setSelectedImage(options[newIndex]);
        console.log('Updated selectedImage:', options[newIndex]);
    }
};

// const handleDownloadImage = async (selectedImage) => {
//     if (selectedImage) {
//         try {
//             const response = await fetch(
//                 `${vendorUrl}${selectedImage.image}`,
//             );
//             if (!response.ok) {
//                 throw new Error(
//                     `Failed to fetch image: ${response.statusText}`,
//                 );
//             }
//             const blob = await response.blob(); // Convert response to blob
//             const blobUrl = URL.createObjectURL(blob); // Create a temporary blob URL

//             const link = document.createElement('a');
//             link.href = blobUrl;
//             link.setAttribute('download', selectedImage.name); // Set the download name
//             document.body.appendChild(link);
//             link.click(); // Trigger the download
//             document.body.removeChild(link);

//             URL.revokeObjectURL(blobUrl); // Clean up the blob URL
//         } catch (error) {
//             console.error('Download failed:', error);
//         }
//     }
// };
// const handleDownloadImage = (selectedImage) => {
//     if (selectedImage) {
//         const link = document.createElement('a');
//         link.href = `${vendorUrl}${selectedImage.image}`;
//         link.setAttribute('download', selectedImage.name);
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//     }
// };
const handleDownloadImage = (selectedImage) => {
    if (selectedImage) {
        const link = document.createElement('a');
        link.href = `${vendorUrl}${selectedImage.image}`;
        link.download = selectedImage.name;
        link.click();
    }
};

const handleSelectImage = (selectedImage, setFieldValue, name, toggleModal) => {
    if (selectedImage) {
        setFieldValue(name, selectedImage.name);
        toggleModal(false);
    }
};

const footerButtons = (
    imageIndex,
    setImageIndex,
    selectedImage,
    setSelectedImage,
    options,
    toggleModal,
    setFieldValue,
    name,
) => [
    {
        title: 'Previous',
        icon: <ArrowBackIosRounded />,
        onClick: () =>
            handlePreviousImage(
                options,
                imageIndex,
                setImageIndex,
                setSelectedImage,
            ),
        disabled: imageIndex === 0,
    },
    {
        title: 'Next',
        icon: <ArrowForwardIosRounded />,
        onClick: () =>
            handleNextImage(
                options,
                imageIndex,
                setImageIndex,
                setSelectedImage,
            ),
        disabled: imageIndex === options.length - 1,
    },
    {
        title: 'Download',
        icon: <DownloadRounded />,
        onClick: () => handleDownloadImage(selectedImage),
    },
    {
        title: 'Select',
        icon: <SaveRounded />,
        onClick: () =>
            handleSelectImage(selectedImage, setFieldValue, name, toggleModal),
    },
    {
        title: 'Close',
        icon: <CloseRounded />,
        onClick: () => handleCloseModal(toggleModal),
    },
];

function generateYearOptions() {
    const currentYear = new Date().getFullYear(); // Get the current year
    let years = [];
    for (let year = currentYear; year >= currentYear - 100; year--) {
        years.push({
            key: year.toString(), // Use the year as the key
            name: year.toString(), // Use the year as the display value
        });
    }
    return years;
}

export const serviceDelivery = [
    'Select ...',
    'Online Only',
    'Online & Onsite',
    'Onsite Only',
];

export const basicInformation = [
    {
        type: 'text',
        className: 'col-md-12',
        label: 'First Name',
        name: 'given_name',
        placeholder: 'Enter value',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        type: 'text',
        className: 'col-md-12',
        label: 'Last Name',
        name: 'family_name',
        placeholder: 'Enter value',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        type: 'select',
        className: 'col-md-12 ',
        label: 'Gender',
        name: 'gender',
        options: [
            {
                key: '',
                name: 'Select ...',
            },
            {
                key: 'Female',
                name: 'Female',
            },
            {
                key: 'a',
                name: 'Male',
            },
        ],
        static: true,
    },
    {
        type: 'select',
        className: 'col-md-12 ',
        label: 'Nationality',
        name: 'nationality',
        options: [
            ...countriesData
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((country) => ({
                    key: country.name,
                    name: country.name,
                })),
        ],
        static: true,
    },
    {
        type: 'text',
        className: 'col-md-12',
        label: 'State/Province',
        name: 'state',
        placeholder: 'Enter value',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        type: 'text',
        className: 'col-md-12',
        label: 'City',
        name: 'city',
        placeholder: 'Enter value',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        type: 'select',
        className: 'col-md-12',
        label: 'Timezone',
        name: 'timezone',
        dbFieldName: 'timezone_list',
        static: true,
    },
    {
        type: 'date',
        className: 'col-md-12',
        label: 'Date of Birth',
        name: 'dateofbirth',
        placeholder: 'Select Date',
        static: true,
    },
];

export const contactInformation = [
    {
        type: 'email',
        className: 'col-md-12',
        label: 'Email Address',
        name: 'email',
        placeholder: 'Enter Value',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        type: 'tel',
        className: 'col-md-12',
        label: 'Mobile Phone',
        name: 'phone_number',
        placeholder: 'Enter Value',
        buttons: [...commonButtons('callBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        type: 'tel',
        className: 'col-md-12',
        label: 'Work Phone',
        name: 'work_phone_number',
        placeholder: 'Enter Value',
        buttons: [...commonButtons('callBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        type: 'url',
        className: 'col-md-12',
        label: 'Primary Weblink',
        name: 'website_url',
        placeholder: 'https://www.example.com',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        type: 'url',
        className: 'col-md-12',
        label: 'Secondary Weblink',
        name: 'secondary_url',
        placeholder: 'https://www.example.com',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        type: 'url',
        className: 'col-md-12',
        label: 'Scheduler Weblink',
        name: 'scheduler_url',
        placeholder: 'https://www.example.com',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
];

export const emergencyInformation = [
    {
        label: 'Blood Type',
        type: 'select',
        className: 'col-md-12',
        name: 'blood_type',
        options: [
            {
                key: 'A-',
                name: 'A-',
            },
            {
                key: 'A+',
                name: 'A+',
            },
            {
                key: 'B-',
                name: 'B-',
            },
            {
                key: 'B+',
                name: 'B+',
            },
            {
                key: 'AB+',
                name: 'AB+',
            },
            {
                key: 'AB-',
                name: 'AB-',
            },
            {
                key: 'O-',
                name: 'O-',
            },
            {
                key: 'O+',
                name: 'O+',
            },
        ],
        static: true,
    },
    {
        label: 'Emergency Contact Name',
        type: 'text',
        className: 'col-md-12',
        name: 'emergency_contact_name',
        placeholder: 'Enter Your Emergency Contact Name',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'Emergency Contact Phone',
        type: 'tel',
        className: 'col-md-12',
        name: 'emergency_contact_phone',
        placeholder: 'Enter Your Emergency Contact Phone',
        buttons: [...commonButtons('callBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'Emergency Contact Email',
        type: 'email',
        className: 'col-md-12',
        name: 'emergency_contact_email',
        placeholder: 'Enter Your Emergency Contact Email',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
        static: true,
    },
];

export const professionalInformation = [
    {
        label: 'Professional Status',
        type: 'select',
        className: 'col-md-12 ',
        required: false,
        name: 'prof_status',
        options: [
            {
                key: 'select',
                name: 'Select ...',
            },
            {
                key: 'active',
                name: 'Active',
            },
            {
                key: 'semi retired',
                name: 'Semi-Retired',
            },
            {
                key: 'retired',
                name: 'Retired',
            },
        ],
        static: true,
    },
    {
        label: 'Professional Title',
        type: 'text',
        className: 'col-md-12',
        name: 'prof_title',
        placeholder: 'Enter Title',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'Professional Department',
        type: 'text',
        className: 'col-md-12',
        name: 'prof_department',
        placeholder: 'Enter Department',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'Professional Company Name',
        type: 'text',
        className: 'col-md-12',
        name: 'prof_company_name',
        placeholder: 'Enter Company Name',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'Professional Company Address',
        type: 'text',
        className: 'col-md-12',
        name: 'prof_company_address',
        placeholder: 'Enter Company Address',
        buttons: [...commonButtons('launchMapBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'Profession',
        type: 'select',
        className: 'col-md-12 ',
        required: false,
        name: 'profession',
        dbFieldName: 'profession_options',
        static: true,
    },
    {
        label: 'Professional License',
        type: 'text',
        className: 'col-md-12',
        name: 'prof_license',
        placeholder: 'Enter Professional License',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'Professional License Website',
        type: 'url',
        className: 'col-md-12',
        name: 'prof_license_url',
        placeholder: 'Enter Professional License Website',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
];

export const socialMedia = [
    {
        label: 'Facebook Weblink',
        type: 'url',
        icons: '/assets/images/logo/fb.png',
        className: 'col-md-12',
        name: 'facebook_url',
        placeholder: 'Enter Facebook Weblink Here',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'Instagram Weblink',
        type: 'url',
        icons: '/assets/images/logo/Insta.png',
        className: 'col-md-12',
        name: 'instagram_url',
        placeholder: 'Enter Instagram Weblink Here',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'LinkedIn Weblink',
        type: 'url',
        icons: '/assets/images/logo/linkedin.png',
        className: 'col-md-12',
        name: 'linkedin_url',
        placeholder: 'Enter Linkedin Weblink Here',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'WhatsApp Phone Number',
        type: 'tel',
        className: 'col-md-12',
        name: 'whatsapp_url',
        placeholder: 'Enter WhatsApp Phone Number Here',
        buttons: [...commonButtons('callBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'WhatsApp Business Weblink',
        type: 'url',
        icons: '/assets/images/communicator_icons/whatsapp_business.png',
        className: 'col-md-12',
        name: 'whatsapp_business_url',
        placeholder: 'Enter Whatsapp Business Weblink Here',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'X Weblink',
        type: 'url',
        icons: '/assets/images/logo/twitter.png',
        className: 'col-md-12',
        name: 'twitter_url',
        placeholder: 'Enter X Weblink Here',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'YouTube Weblink',
        type: 'url',
        icons: '/assets/images/logo/youtube.jpg',
        className: 'col-md-12',
        name: 'youtube_url',
        placeholder: 'Enter Youtube Weblink Here',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
];

export const datingInformation = [
    {
        label: 'Relationship Status',
        type: 'select',
        className: 'col-md-12 ',
        name: 'relationship_status',
        options: [
            {
                name: 'Committed',
            },
            {
                name: 'Divorced',
            },
            {
                name: 'Do not share',
            },
            {
                name: 'Engaged',
            },
            {
                name: 'Married',
            },
            {
                name: 'Single',
            },
            {
                name: 'Separated',
            },
            {
                name: 'Widowed',
            },
        ],
        static: true,
    },
    {
        label: 'Relationship Status Visibility',
        type: 'select',
        className: 'col-md-12 ',
        name: 'dating_visibility',
        options: [
            {
                name: 'No',
            },
            {
                name: 'Yes',
            },
        ],
        static: true,
    },
    {
        label: 'Cupid Media Weblink',
        type: 'url',
        icons: '/assets/images/dating/cupid_media.jpeg',
        className: 'col-md-12',
        name: 'cupid_url',
        placeholder: 'Enter Cupid Media Weblink Here',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'Facebook Dating Weblink',
        type: 'url',
        icons: '/assets/images/dating/fb_dating.jpeg',
        className: 'col-md-12',
        name: 'fb_dating_url',
        placeholder: 'Enter Facebook Dating Weblink Here',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'Match.com Weblink',
        type: 'url',
        icons: '/assets/images/dating/match_com.jpeg',
        className: 'col-md-12',
        name: 'match_url',
        placeholder: 'Enter Match.com Weblink Here',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'eHarmony Weblink',
        type: 'url',
        icons: '/assets/images/dating/eharmony.jpeg',
        className: 'col-md-12',
        name: 'eharmony_url',
        placeholder: 'Enter eHarmony Weblink Here',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'Kelleher International Weblink',
        type: 'url',
        icons: '/assets/images/dating/kelleher_international.jpeg',
        className: 'col-md-12',
        name: 'kelleher_url',
        placeholder: 'Enter Kelleher International Weblink Here',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
    {
        label: 'Tinder Weblink',
        type: 'url',
        icons: '/assets/images/dating/tinder.jpeg',
        className: 'col-md-12',
        name: 'tinder_url',
        placeholder: 'Enter Tinder Weblink Here',
        buttons: [...commonButtons('launchBtn', 'pasteBtn', 'clearBtn')],
        static: true,
    },
];

export const publicProfile = [
    {
        label: 'Webstore Display',
        type: 'select',
        className: 'col-md-12 ',
        name: 'profile_display',
        options: [
            {
                identifier: 'individual',
                name: 'Display as Individual',
            },
            {
                identifier: 'organization',
                name: 'Display as Organization',
            },
        ],
        static: true,
    },
    {
        label: 'Social Media Weblink Visibility',
        type: 'select',
        className: 'col-md-12 ',
        name: 'social_visibility',
        options: [
            {
                name: 'No',
            },
            {
                name: 'Yes',
            },
        ],
        static: true,
    },
    {
        label: 'Webstore Visibility',
        type: 'select',
        className: 'col-md-12 ',
        name: 'webstore_visibility',
        options: [
            {
                name: 'No',
            },
            {
                name: 'Yes',
            },
        ],
        static: true,
    },
    {
        label: 'Profile Slug for Your Custom Webstore',
        type: 'custom_url',
        className: 'col-md-12',
        name: 'user_slug',
        placeholder: 'Edit your profile slug',
        buttons: [...commonButtons('launchBtn', 'copyBtn', 'clearBtn')],
        static: true,
        urlPrefix: `${siteUrl}/user/`,
    },
    {
        type: 'url',
        className: 'col-md-12',
        label: 'Forwarded Domain for Payments',
        name: 'forwarded_domain',
        placeholder: 'Enter your Domain to Forward',
        buttons: [
            ...commonButtons('helpBtn', 'launchBtn', 'pasteBtn', 'clearBtn'),
        ],
        static: true,
        modalData: {
            title: 'Domain Forwarding and Masking',
            body: (
                <>
                    <Typography>
                        Purchase a branded Internet domain and forward it (i.e.,
                        point it) at the weblink of your Toolkit.law User
                        Webstore.
                    </Typography>
                    <Typography sx={{ my: 2 }}>
                        Go to a domain registrar like GoDaddy, purchase your
                        domain, go into the domain settings, select the
                        “Forwarding” settings, then enter the complete weblink
                        of your Toolkit.law User Webstore.
                    </Typography>
                    <Typography>
                        Be sure to turn on “masking” so that when someone types
                        your branded weblink, it keeps displaying your branded
                        weblink rather than switching to a Toolkit.law weblink.
                    </Typography>
                </>
            ),
        },
    },
    {
        type: 'select',
        className: 'col-md-12 ',
        label: 'Profile Photo',
        name: 'photo',
        options: [
            {
                identifier: 'avatar',
                name: 'Avatar',
            },
            {
                identifier: 'social',
                name: 'Social Media Account',
            },
        ],
        static: true,
    },
    {
        type: 'select',
        className: 'col-md-12',
        label: 'Header Background',
        name: 'header_background',
        dbFieldName: 'background_options',
        static: true,
        buttons: !isProduction
            ? [
                  {
                      title: 'Help',
                      tooltip: 'Launch',
                      type: 'button',
                      class: 'btn btn-sm',
                      imgSrc: <LaunchRounded />,
                  },
              ]
            : [],
        modalData: {
            size: 'md',
            title: 'Header Background',
            footerImage: (
                imageIndex,
                setImageIndex,
                selectedImage,
                setSelectedImage,
                options,
                toggleModal,
                setFieldValue,
                name,
            ) =>
                footerButtons(
                    imageIndex,
                    setImageIndex,
                    selectedImage,
                    setSelectedImage,
                    options,
                    toggleModal,
                    setFieldValue,
                    name,
                ),

            bodyImage: (selectedImage) => {
                return selectedImage ? (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            {selectedImage.name}
                        </Typography>
                        <Box
                            component="img"
                            src={`${vendorUrl}${selectedImage.image}`}
                            alt={selectedImage.name}
                            sx={{
                                width: '100%',
                                height: {
                                    xs: '210px',
                                    sm: '270px',
                                    md: '300px',
                                },
                                borderRadius: 1,
                            }}
                        />
                    </Box>
                ) : (
                    <Typography>No image selected</Typography>
                );
            },
        },
    },
    {
        type: 'select',
        className: 'col-md-12 ',
        label: 'Section Order',
        name: 'section_order',
        options: [
            {
                identifier: 'about',
                name: 'About section first, Services section second',
            },
            {
                identifier: 'services',
                name: 'Services section first, About section Second',
            },
        ],
        static: true,
    },
    {
        type: 'textarea',
        className: 'col-md-12',
        label: 'About',
        name: 'about',
        placeholder: 'Enter your About Here',
        static: true,
        row: 5,
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
    },
    {
        type: 'video_player',
        className: 'col-md-12',
        videoPlayer: {
            videoUrl: 'toolkitlaw-user-webstores-flyer-video.mp4',
            thumbnailUrl: `video-thumbnail-user-webstores.jpg`,
        },
        static: true,
    },
    {
        type: 'pdf_viewer',
        className: 'col-md-12',
        pdfViewer: { fileUrl: 'toolkitlaw-user-webstores-flyer.pdf' },
        static: true,
    },
];

export const educationInformation = [
    {
        type: 'text',
        className: 'col-md-12',
        label: '',
        name: 'id',
        hidden: true,
    },
    {
        type: 'text',
        className: 'col-md-12',
        label: 'Institution',
        name: 'university_name',
        row: 3,
        placeholder: 'Enter your Institution Here',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
    },
    {
        type: 'text',
        className: 'col-md-12',
        label: 'Degree',
        name: 'degree_title',
        row: 3,
        placeholder: 'Enter your Degree Here',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
    },
    {
        type: 'select',
        className: 'col-md-12 ',
        label: 'Degree Completion Year',
        name: 'degree_completion_date',
        options: [
            {
                key: 'Select Year',
                name: 'Select ...',
            },
            ...generateYearOptions(),
        ],
    },
];

export const servicesInformation = [
    {
        type: 'custom_component',
        className: 'col-md-12',
        value: (
            <PriceDescription
                currentId={servicesTitle}
                targetId={webstoreTitle}
            />
        ),
        static: true,
    },
    {
        type: 'text',
        className: 'col-md-12',
        label: '',
        name: 'id',
        hidden: true,
    },
    {
        type: 'select',
        className: 'col-md-12',
        label: 'Category',
        name: 'services_category',
        dbFieldName: 'services_category',
    },
    {
        type: 'number',
        className: 'col-md-12',
        label: 'USD Price/Hour',
        name: 'price',
        placeholder: 'Enter your Price Here',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
        icons: <AttachMoney />,
        iconType: 'icon',
    },
    {
        type: 'textarea',
        className: 'col-md-12',
        label: 'Description',
        name: 'description',
        row: 3,
        placeholder: 'Enter your Description Here',
        buttons: [...commonButtons('pasteBtn', 'clearBtn')],
    },
    {
        label: 'Service Delivery',
        type: 'select',
        className: 'col-md-12 ',
        name: 'service_delivery',
        options: serviceDelivery.map((item) => ({
            key: convertToKey(item),
            name: item,
        })),
    },
    {
        label: 'Service Visibility',
        type: 'select',
        className: 'col-md-12 ',
        name: 'service_visibility',
        options: [
            {
                identifier: 'hidden',
                name: 'Hidden',
            },
            {
                identifier: 'visible',
                name: 'Visible',
            },
        ],
    },
];

export const adRotation = {
    ads_rotation: 'daily',
};

export const globalAds = [
    {
        ad_visibility: 1,
        ad_images: [
            {
                ad_desktop_image_url: isProduction
                    ? 'images/1730998403.svg'
                    : 'images/1730997776.svg',
                ad_mobile_image_url: isProduction
                    ? 'images/1730998409.svg'
                    : 'images/1730998022.svg',
                ad_weblink: `${siteUrl}/settings`,
            },
        ],
    },
];
